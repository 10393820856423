import { init, BrowserTracing, BrowserProfilingIntegration } from "@sentry/browser";
import { SENTRY_DEFAULT_SAMPLE_RATE } from "./constants";
import { getCookie } from "./cookies";

const initSentry = ({ enabled, environment, dsn, release, sampleRate }) => {
  init({
    enabled,
    environment,
    dsn,
    release,
    debug: import.meta.env.VITE_SENTRY_DEBUG === "true", //Turning this on allows is to see web vitals info in the console log
    integrations: [
      // Add browser profiling integration to the list of integrations
      new BrowserTracing(),
      new BrowserProfilingIntegration()
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/sampling/
    sampleRate: 1, // errors
    tracesSampler: () => {
      //Using this rather than tracesSamplerRate allows us to optionally change this as we go!
      // We could optionally use a higher default sample rate for high visibility pages

      /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
      try {
        //This allows the cypress tests to not flake.
        if (getCookie("of_test") === "test") {
          return 1;
        }
      } catch {
        //This fails during build only; does not affect test usage
      }

      return sampleRate || SENTRY_DEFAULT_SAMPLE_RATE;
    }
  });
};

export default initSentry;
