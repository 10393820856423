import initSentry from "$lib/utilities/sentry";
import { dev } from "$app/environment";

import { captureException } from "@sentry/browser";

initSentry({
  enabled: !dev || import.meta.env.VITE_SENTRY_DEBUG === "true",
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  sampleRate:
    import.meta.env.VITE_SENTRY_SAMPLE_RATE && parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE)
});

/** @type {import('@sveltejs/kit').HandleClientError} */
export async function handleError({ error, event, status, message }) {
  const errorId = crypto.randomUUID();

  // example integration with https://sentry.io/
  captureException(error, {
    extra: { event, errorId, status }
  });

  return {
    url: event.url.pathname,
    message,
    errorId
  };
}
